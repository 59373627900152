
import Vue from 'vue'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils/tables'
import { ActiveFilterItem, IHeaderOption, IPagingInput, ISortingInput, KwikDialog, KwikMediumContainer, KwikPageToolbar, KwikTableAction, KwikTooltipButtonIcon } from 'kwik-vue'
import { IBatch, IBatchesGetInput } from '../types'
import { dispatchDisposeBatch, dispatchGetCompanyBatches, dispatchReturnBatch } from '../store/actions'
import { readBatchesCount, readBatchesCurrentPage } from '../store/getters'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import FiltersBar from '@/components/filtersBar/FiltersBar.vue'
import { clockToFormatDate } from '../../../utils/clockManager'

export default Vue.extend({
  name: 'FFCompanyBatches',
  components: { KwikMediumContainer, KwikPageToolbar, KwikTableAction, KwikTooltipButtonIcon, FiltersBar, KwikDialog },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  },
  data () {
    return {
      options: {} as any,
      loading: true,
      filters: [] as ActiveFilterItem[],
      showFilters: true,
      showBatchActions: false,
      showConfirm: false,
      currentOperationName: '',
      currentBatch: null as IBatch | null,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Lotti', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'Codice',
          sortable: true,
          value: 'code',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Materiale',
          sortable: true,
          value: 'material_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità',
          sortable: true,
          value: 'total_quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Unità di misura',
          sortable: true,
          value: 'material_unit_of_measure',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Scadenza',
          sortable: true,
          value: 'expiration_clock',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    filterOptions (): FilterOption[] {
      return [
        {
          key: 'code',
          label: 'Codice',
          field: 'code',
          type: FilterTypes.STRING
        },
        {
          key: 'material_id',
          label: 'Materiale',
          field: 'material_id',
          type: FilterTypes.MATERIAL
        }
      ] as FilterOption[]
    },
    batchesItems () {
      return readBatchesCurrentPage(this.$store)
    },
    total () {
      return readBatchesCount(this.$store)
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      let materialId = null
      let code = null
      if (this.filters) {
        this.filters.forEach((filter) => {
          switch (filter.field) {
            case 'code':
              code = filter.value
              break
            case 'material_id':
              materialId = filter.value
              break
            default:
            // nothing
          }
        })
      }
      const payload: IBatchesGetInput = {
        paging: paging,
        sorting: sorting,
        material_id: materialId,
        code: code,
        warehouse_model_id: null
      }
      await dispatchGetCompanyBatches(this.$store, payload)
      this.loading = false
    },
    toggleFilter () {
      this.showFilters = !this.showFilters
    },
    filterData (activeFilters: ActiveFilterItem[]) {
      this.filters = activeFilters
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    openBatchActions (currentBatch: IBatch) {
      this.currentBatch = currentBatch
      this.showBatchActions = true
    },
    closeBatchActions () {
      this.showBatchActions = false
      this.showConfirm = false
      this.currentOperationName = ''
      this.currentBatch = null
    },
    async confirmBatchActions () {
      if (this.currentBatch === null) return
      if (this.currentOperationName === 'DISPOSE') {
        await dispatchDisposeBatch(this.$store, this.currentBatch.id)
      } else {
        // RETURN
        await dispatchReturnBatch(this.$store, this.currentBatch.id)
      }
      this.loadPage()
      this.closeBatchActions()
    },
    getBatchPopupTitle () {
      if (this.currentBatch == null) {
        return ''
      } else {
        return 'Operazione sul lotto ' + this.currentBatch.code
      }
    },
    getOperationText () {
      if (this.currentOperationName === 'DISPOSE') {
        return 'Confermi l\'operazione di smaltimento sul lotto selezionato? NON sarà possibile recuperare il lotto e verranno addebitati i costi di smaltimento'
      } else {
        return 'Confermi l\'operazione di reso sul lotto selezionato? NON sarà possibile recuperare il lotto e si otterrà un rimborso dal fornitore se applicabile. Nel caso NON sia applicabile il lotto verrà direttamente smaltito!'
      }
    },
    startDisposeBatch () {
      this.showConfirm = true
      this.currentOperationName = 'DISPOSE'
    },
    startReturnBatch () {
      this.showConfirm = true
      this.currentOperationName = 'RETURN'
    }
  }
})
